var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.listTab,
              attrs: {
                label: _vm.$t("vlink.videoSquare.list"),
                name: _vm.listTab,
              },
            },
            [
              _c(
                "div",
                { staticClass: "app-half" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.videoSquare.listLoading,
                          expression: "videoSquare.listLoading",
                        },
                      ],
                      attrs: {
                        data: this.videoDeviceList,
                        "element-loading-text": "loading",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                        stripe: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: _vm.$t("vlink.videoSquare.deviceName"),
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "deviceId",
                          label: _vm.$t("vlink.videoSquare.deviceCode"),
                          width: "240",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturer",
                          label: _vm.$t("vlink.videoSquare.deviceManufacturer"),
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "model",
                          label: _vm.$t(
                            "vlink.videoSquare.deviceFirmwareVersion"
                          ),
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _c("Player", {
                        attrs: {
                          "video-url": _vm.videoOneUrl,
                          height: "470px",
                          autoplay: true,
                          "has-audio": false,
                          isFullResize: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("hr", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "app-half" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.videoSquare.listLoading,
                          expression: "videoSquare.listLoading",
                        },
                      ],
                      attrs: {
                        data: this.videoDeviceList2,
                        "element-loading-text": "loading",
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                        stripe: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: _vm.$t("vlink.videoSquare.deviceName"),
                          width: "180",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "deviceId",
                          label: _vm.$t("vlink.videoSquare.deviceCode"),
                          width: "240",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "manufacturer",
                          label: _vm.$t("vlink.videoSquare.deviceManufacturer"),
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "model",
                          label: _vm.$t(
                            "vlink.videoSquare.deviceFirmwareVersion"
                          ),
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _c("Player", {
                        attrs: {
                          "video-url": _vm.videoTwoUrl,
                          height: "470px",
                          autoplay: true,
                          "has-audio": false,
                          isFullResize: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }