<template>
  <div class="app-container">
    <el-tabs v-model="activeTabName" type="card">
      <el-tab-pane
        :label="$t('vlink.videoSquare.list')"
        :name="listTab"
        :key="listTab"
      >
        <!-- 第一个可播放页面 -->
        <div class="app-half">
          <el-table
            v-loading="videoSquare.listLoading"
            :data="this.videoDeviceList"
            element-loading-text="loading"
            border
            fit
            highlight-current-row
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t('vlink.videoSquare.deviceName')"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="deviceId"
              :label="$t('vlink.videoSquare.deviceCode')"
              width="240"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="manufacturer"
              :label="$t('vlink.videoSquare.deviceManufacturer')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="model"
              :label="$t('vlink.videoSquare.deviceFirmwareVersion')"
              align="center"
            >
            </el-table-column>
            <!--          <el-table-column :label="$t('vlink.videoSquare.status')" width="180" align="center">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.online==1? $t('vlink.videoSquare.online') :$t('vlink.videoSquare.offline')}}</el-tag>
              </div>
            </template>
          </el-table-column>-->
          </el-table>
          <br />
          <br />
          <div>
            <Player
              :video-url="videoOneUrl"
              height="470px"
              :autoplay="true"
              :has-audio="false"
              :isFullResize="false"
            />
          </div>
        </div>

        <!-- 分割线-->
        <!--        <div class="line" >-->
        <!--        </div>-->
        <hr class="line" />
        <!--  第二个摄像头播放页面  -->
        <div class="app-half">
          <el-table
            v-loading="videoSquare.listLoading"
            :data="this.videoDeviceList2"
            element-loading-text="loading"
            border
            fit
            highlight-current-row
            stripe
          >
            <el-table-column
              prop="name"
              :label="$t('vlink.videoSquare.deviceName')"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="deviceId"
              :label="$t('vlink.videoSquare.deviceCode')"
              width="240"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="manufacturer"
              :label="$t('vlink.videoSquare.deviceManufacturer')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="model"
              :label="$t('vlink.videoSquare.deviceFirmwareVersion')"
              align="center"
            >
            </el-table-column>
            <!--          <el-table-column :label="$t('vlink.videoSquare.status')" width="180" align="center">
            <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.online==1? $t('vlink.videoSquare.online') :$t('vlink.videoSquare.offline')}}</el-tag>
              </div>
            </template>
          </el-table-column>-->
          </el-table>
          <br />
          <br />
          <div>
            <Player
              :video-url="videoTwoUrl"
              height="470px"
              :autoplay="true"
              :has-audio="false"
              :isFullResize="false"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import LivePlayer from "@liveqing/liveplayer";
import Player from "@/components/jessibuca";
const defineTab = {
  listTab: "list",
};

export default {
  name: "VideoSquare",
  components: {
    // LivePlayer,
    Player,
  },
  data() {
    return {
      videoDeviceList: [
        {
          deviceId: "34020000001320000025",
          name: "11栋505润信数据前台",
          manufacturer: "大华",
          model: "IPC-HFW3233M-AS-I1",
          firmware: "V1.0",
          transport: "UDP",
          host: {
            ip: "192.168.2.89",
            port: 53868,
            address: "192.168.2.89:53868",
          },
          online: 1,
          channelMap: {
            "34020000001320000025": {
              channelId: "34020000001320000025",
              name: "11栋505润信数据前台",
              manufacture: "大华",
              model: "IPC-HFW3233M-AS-I1",
              owner: "Owner",
              civilCode: "CivilCode",
              block: null,
              address: "Address",
              parental: 0,
              parentId: null,
              safetyWay: 0,
              registerWay: 1,
              certNum: null,
              certifiable: 0,
              errCode: 0,
              endTime: null,
              secrecy: "0",
              ipAddress: null,
              port: 0,
              password: null,
              status: 1,
              longitude: 0,
              latitude: 0,
            },
          },
        },
      ],
      videoDeviceList2: [
        {
          deviceId: "34020000001320000024",
          name: "11栋505润信数据实验室",
          manufacturer: "大华",
          model: "IPC-HFW3233M-AS-I1",
          firmware: "V1.0",
          transport: "UDP",
          host: {
            ip: "192.168.2.89",
            port: 53868,
            address: "192.168.2.89:53868",
          },
          online: 1,
          channelMap: {
            "34020000001320000024": {
              channelId: "34020000001320000024",
              name: "11栋505润信数据实验室",
              manufacture: "大华",
              model: "IPC-HFW3233M-AS-I1",
              owner: "Owner",
              civilCode: "CivilCode",
              block: null,
              address: "Address",
              parental: 0,
              parentId: null,
              safetyWay: 0,
              registerWay: 1,
              certNum: null,
              certifiable: 0,
              errCode: 0,
              endTime: null,
              secrecy: "0",
              ipAddress: null,
              port: 0,
              password: null,
              status: 1,
              longitude: 0,
              latitude: 0,
            },
          },
        },
      ], //设备列表
      videoComponentList: [],
      openDelay: 1000,
      activeTabName: defineTab.listTab,
      createLoading: false,
      videoSquare: {
        title: null,
        isEdit: false,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
        },
        addDialogVisible: false,
        formRules: {},
      },
      videoOneUrl: `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000024@34020000001320000024.flv`,
      videoTwoUrl: `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000025@34020000001310000001.flv`,
      // 'webrtc://10.1.200.199:30111/live/34020000001320000025@34020000001310000001',
    };
  },
  computed: {
    listTab() {
      return defineTab.listTab;
    },
  },
  mounted() {
    this.initData();
    this.updateLooper = setInterval(this.initData, 10000);
    // if (this.isMobile()) {
    //   this.videoOneUrl.replace("flv", "m3u8");
    //   this.videoTwoUrl.replace("flv", "m3u8");
    // }
  },
  destroyed() {
    this.$destroy("videojs");
  },
  methods: {
    initData: function () {
      this.getDeviceList();
    },

    getDeviceList: function () {
      let that = this;
      //现在为假数据，后续可以替换为我们的设备属性上报查询
    },
  },
};
</script>

<style scoped>
#pane-list {
  width: 100%;
  display: flex;
}
#pane-list .app-half {
  flex: 1;
}
#el-tab-pane {
  margin-inside: 20px;
}
.line {
  height: 100vh;
  flex: inherit;
  width: 2px !important;
  background: #234d95;
  margin: 0 5px;
  box-sizing: content-box;
}
</style>